// src/JwtToken.js
import React, { useState } from 'react';
import { KJUR } from 'jsrsasign';
import '../JwtToken.css';
import { Analytics } from '@vercel/analytics/react';
import { Helmet } from 'react-helmet';

const JwtToken = () => {
  const [payload, setPayload] = useState('');
  const [secret, setSecret] = useState('');
  const [token, setToken] = useState('');

  const handlePayloadChange = (e) => {
    setPayload(e.target.value);
  };

  const handleSecretChange = (e) => {
    setSecret(e.target.value);
  };

  const handleGenerateToken = () => {
    try {
      const payloadObject = JSON.parse(payload);
      const header = { alg: 'HS256', typ: 'JWT' };
      const generatedToken = KJUR.jws.JWS.sign(
        'HS256', // algorithm
        JSON.stringify(header), // header
        JSON.stringify(payloadObject), // payload
        secret // secret
      );
      setToken(generatedToken);
    } catch (error) {
      alert('Invalid JSON payload or secret');
    }
  };

  return (
    <>
    <Helmet>
        <title>Jwt Generator</title>
        <meta name="description" content="Quickly generate and manage JWTs with our easy online tool. No sign-up needed—just input your details and get your token." />
        <meta name="keywords" content="jwt generator" />
    </Helmet>
    <Analytics />
    <div className="gradient-background d-flex flex-column justify-content-center  ">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img src="/jwt_icon.png" style={{maxHeight:'15vh'}}></img>
        <div className="max-w-lg mx-auto p-4 bg-white shadow-md rounded-lg" style={{ marginTop: '1vh' }}>
          <h2 className="text-2xl font-semibold mb-4 text-gray-700">JWT Token Generator</h2>
          <form className="space-y-4" style={{minWidth:'30vw'}}>
            <div>
              <label className="block text-gray-600 mb-1">
                Payload (JSON):
              </label>
              <textarea
                rows="4"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={payload}
                onChange={handlePayloadChange}
                placeholder='{"user": "example"}'
              />
            </div>
            <div>
              <label className="block text-gray-600 mb-1">
                Secret:
              </label>
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={secret}
                onChange={handleSecretChange}
                placeholder="Enter your secret"
              />
            </div>
            <div>
              <button
                type="button"
                onClick={handleGenerateToken}
                className="w-full bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Generate JWT Token
              </button>
            </div>
            {token && (
              <div className="mt-4">
                <h3 className="text-xl font-medium text-gray-700">Generated JWT Token:</h3>
                <textarea
                  rows="4"
                  className="w-full mt-2 p-2 border border-gray-300 rounded-md bg-gray-100"
                  readOnly
                  value={token}
                />
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
    </>
  );
};

export default JwtToken;
