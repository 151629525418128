import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Calendar } from 'primereact/calendar';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { FloatLabel } from 'primereact/floatlabel';

const AgeCalculator = () => {
  const [birthdate, setBirthdate] = useState('');
  const [ageDetails, setAgeDetails] = useState(null);

  const calculateAge = () => {
    if (!birthdate) return;
    
    const birthDateObj = new Date(birthdate);
    const today = new Date();
    
    let years = today.getFullYear() - birthDateObj.getFullYear();
    let months = today.getMonth() - birthDateObj.getMonth();
    let days = today.getDate() - birthDateObj.getDate();
    
    if (days < 0) {
      months--;
      days += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    }
    
    if (months < 0) {
      years--;
      months += 12;
    }

    const totalDays = Math.floor((today - birthDateObj) / (1000 * 60 * 60 * 24));
    const totalWeeks = Math.floor(totalDays / 7);
    const totalHours = Math.floor(totalDays * 24);

    setAgeDetails({
      years,
      months,
      days,
      totalDays,
      totalWeeks,
      totalHours
    });
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat().format(number);
  };

  return (
    <>
          
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-200">
      <motion.div
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        
        <h2 className="text-3xl font-bold mb-6 text-gray-700">Age Calculator</h2>
        <div className="card flex flex-wrap gap-3 p-fluid" style={{marginBottom: "3vh", marginTop:"5vh"}}>
              <div className="flex-auto">
                <FloatLabel>
                    <Calendar style={{minHeight:'4vh'}} variant="filled" value={birthdate} onChange={(e) => setBirthdate(e.value)} showIcon />
                    <label htmlFor="birth_date">Enter Date Of Birth</label>
                </FloatLabel>
              </div>
          </div>
        
        <motion.button
          onClick={calculateAge}
          className="w-full bg-blue-600 text-white p-3 rounded-lg hover:bg-blue-700 transition"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Calculate Age
        </motion.button>
        {ageDetails && (
          <motion.div
            className="mt-6 text-lg text-gray-700 space-y-2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <p><span className="font-semibold">Years:</span> {formatNumber(ageDetails.years)}</p>
            <p><span className="font-semibold">Months:</span> {formatNumber(ageDetails.months)}</p>
            <p><span className="font-semibold">Days:</span> {formatNumber(ageDetails.days)}</p>
            <br />
            <p><span className="font-semibold">Total Days:</span> {formatNumber(ageDetails.totalDays)}</p>
            <p><span className="font-semibold">Total Weeks:</span> {formatNumber(ageDetails.totalWeeks)}</p>
            <p><span className="font-semibold">Total Hours:</span> {formatNumber(ageDetails.totalHours)}</p>
          </motion.div>
        )}
      </motion.div>
    </div>
          
    </>
  );
};

export default AgeCalculator;
