import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';

import JwtToken from './components/JwtToken';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './components/Home';
import { Helmet } from 'react-helmet';
import AgeCalculator from './components/AgeCalculator';

function App() {
  return (
    <>
    <Navbar/>
    <Helmet>
        <title>Hackerlytics</title>
        <meta name="description" content="Tools designed to streamline your workflow and make your tasks easier" />
    </Helmet>
    <Router>
      <Routes>
        <Route path="/jwtgenerator" element={<JwtToken />} />
        <Route path="/" element={<Home />} />
        <Route path="/agecalculator" element={<AgeCalculator />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
